<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 14 14">
    <path
      :style="iconStyle"
      d="M13.491,1.577l-2.844.656a.661.661,0,0,1-.752-.38L8.583-1.209a.655.655,0,0,1,.189-.766l1.657-1.356A10.134,10.134,0,0,0,5.584-8.176L4.227-6.519a.656.656,0,0,1-.766.189L.4-7.643A.664.664,0,0,1,.016-8.4l.656-2.844a.656.656,0,0,1,.64-.509A12.686,12.686,0,0,1,14,.938.655.655,0,0,1,13.491,1.577Z"
      transform="translate(14 2.25) rotate(180)"
    />
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '14',
    },
    width: {
      type: String,
      default: '14',
    },
    color: {
      type: String,
      default: 'label-text',
    },
  },
  computed: {
    /**
     * Icon Style
     * @param {void}
     * @returns {string} - Color
     * @description Assigns the specific color to the icon
     */
    iconStyle: function () {
      return {
        fill: ['$' + this.color, `var(--${this.color})`],
      }
    },
  },
}
</script>
